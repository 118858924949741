<template>
    <div>
      <b-container>
        <b-modal ref="gmapsModal" hide-footer title="Google Maps">
          <div class="d-block text-center">
            <h4>Maps para o endereço: {{popups.gmaps.name}}</h4>
          </div>
          <iframe v-if="this.form.ds_googlemaps.length>0" :src="googlemapsURI" height="200" width="300"></iframe>
          <b-btn class="mt-3" variant="outline-info" block @click="gmapsClose">Fechar</b-btn>
        </b-modal>

        <b-row>
          <b-col>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Nome:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  v-model="form.ds_local_evento"
                                  placeholder="Digite o nome">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Estado:
                      </b-input-group-prepend>
                      <b-form-select v-on:change="selState" v-model="form.id_estado" :options="selects.state" size="sm" />
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Cidade:
                      </b-input-group-prepend>
                      <b-form-select v-model="form.id_municipio" :options="selects.city" size="sm" />
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Tipo:
                      </b-input-group-prepend>
                      <b-form-select v-model="form.id_tipo_local" :options="selects.placetype" size="sm" />
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text>
                    Link do local:
                    <span
                      v-b-tooltip.hover
                      title="Clique para abrir"
                      style="cursor:pointer;padding-left: 4px;"
                      @click="gotoFarFromHome(form.url)"
                    >
                      <i class="fab fa-chrome"></i>
                    </span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="url"
                    type="text"
                    name="url"
                    maxlength="1000"
                    v-model="form.url"
                    placeholder="Informe a url"
                  ></b-form-input>
                </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Endereço:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                
                                  name="name"
                                  maxlength="600"
                                  v-model="form.ds_googlemaps"
                                  placeholder="Digite o endereço">
                      </b-form-input>
                      <b-button type="button" variant="outline-info" size="sm"  @click="openMaps">
                        <span>Ver no Google Maps</span>
                      </b-button>
                  </b-input-group>
              </b-row>
               <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Capacidade:
                      </b-input-group-prepend>
                      <b-form-input v-model="form.occupation_info" type="text" name="name" maxlength="100" placeholder="Quantidade de pessoas"></b-form-input>
                  </b-input-group>
              </b-row>
               <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Palavras Chave:
                      </b-input-group-prepend>
                      <b-form-input v-model="form.meta_keywords" type="text" name="name" maxlength="100" placeholder="Ex: ingressos online"></b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="6" class="ml-0 pl-0">
                   <b-input-group size="sm">
                    <b-input-group-prepend
                      is-text
                    >Breve Descrição:</b-input-group-prepend>
                    <div
                      class="col-12 m-0 p-0"
                      style="height:120px; margin-bottom:50px;margin-left: 0px;"
                    >
                      <quill-editor
                        v-on:content-changed="textChanged"
                        v-model="form.meta_description"
                        ref="editor"
                        :options="components.quillOptions"
                      ></quill-editor>
                    </div>
                  </b-input-group>
                </b-col>
                <b-col cols="6" class="ml-0 pl-0">
                   <b-input-group size="sm">
                    <b-input-group-prepend
                      is-text
                    > Informações Adicionais:</b-input-group-prepend>
                    <div
                      class="col-12 m-0 p-0"
                      style="height:120px; margin-bottom:50px;margin-left: 0px;"
                    >
                      <quill-editor
                        v-on:content-changed="textChanged"
                        v-model="form.ticketbox_info"
                        ref="editor"
                        :options="components.quillOptions"
                      ></quill-editor>
                    </div>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Banner:
                      </b-input-group-prepend>
                      <div id="my-upload" style="display: flex;">
                        <vue-upload-multiple-image
                          :key="idupload"
                          @upload-success="uploadImageSuccess"
                          @before-remove="beforeRemove"
                          @edit-image="editImage"
                          @data-change="dataChange"
                          :data-images="form.images"
                          :dragText="components.picOptions.dragText"
                          :browseText="components.picOptions.browseText"
                          :primaryText="components.picOptions.primaryText"
                          :markIsPrimaryText="components.picOptions.markIsPrimaryText"
                          :popupText="components.picOptions.popupText"
                          :dropText="components.picOptions.dropText"
                          :showPrimary="false"
                          :multiple="false"
                          idUpload="uploadImageSuccess"
                          idEdit="editImage"
                        ></vue-upload-multiple-image>
                      </div>
                  </b-input-group>
                </b-col>
                <b-col cols="2">
                  <div class="directlink">
                    <a
                      title="Abrir imagem usada como banner"
                      :href="form.imageURIBanner"
                      target="_blank"
                    >Banner</a>/
                    <a
                      title="Abrir imagem original"
                      :href="form.imageURIOriginal"
                      target="_blank"
                    >Imagem original</a>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Logo:
                      </b-input-group-prepend>
                      <div id="my-upload" style="display: flex;">
                        <vue-upload-multiple-image
                          :key="idupload2"
                          @upload-success="uploadImageSuccess2"
                          @before-remove="beforeRemove2"
                          @edit-image="editImage2"
                          @data-change="dataChange2"
                          :data-images="form.images2"
                          :dragText="components.picOptions.dragText"
                          :browseText="components.picOptions.browseText"
                          :primaryText="components.picOptions.primaryText"
                          :markIsPrimaryText="components.picOptions.markIsPrimaryText"
                          :popupText="components.picOptions.popupText"
                          :dropText="components.picOptions.dropText"
                          :showPrimary="false"
                          :multiple="false"
                          idUpload="uploadImageSuccess2"
                          idEdit="editImage2"
                        ></vue-upload-multiple-image>
                      </div>
                  </b-input-group>
                </b-col>
                <b-col cols="2">
                  <div class="directlink">
                    <a
                      title="Abrir imagem usada como banner"
                      :href="form.imageURIBanner2"
                      target="_blank"
                    >Logo</a>/
                    <a
                      title="Abrir imagem original"
                      :href="form.imageURIOriginal2"
                      target="_blank"
                    >Imagem original</a>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-checkbox id="active"
                                      v-model="form.in_ativo"
                                    
                                      value="1">
                      <span v-if="form.in_ativo == 1">Ativo</span>
                      <span v-else>Inativo</span>
                      </b-form-checkbox>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-button type="button" variant="success" size="sm" @click="save">
                  <v-wait for="inprocess">
                      <template slot="waiting">
                          Carregando...
                      </template>
                  </v-wait>
                  <v-wait for="inprocessSave">
                      <template slot="waiting">
                          Salvando...
                      </template>
                  </v-wait>
                  <span v-if="!processing">Salvar</span>
                </b-button>
              </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueHead from 'vue-head';
import VueUploadMultipleImage from "vue-upload-multiple-image";
import config from "@/config";
import VueQuillEditor from "vue-quill-editor";
import { func } from "@/functions";
import { cityService } from '../../components/common/services/city';
import { stateService } from '../../components/common/services/state';
import { placetypeService } from '../../components/common/services/placetype';
import { placeService } from '../../components/common/services/place';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueHead);
Vue.use(VueQuillEditor);

export default {
  mixins: [func],
  props: ['id'],
  name: 'place-add',
  components: {
    VueUploadMultipleImage
  },
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: `Gênero - ${this.typeOf}`,
      }
    },
  },
  created() {
    this.populatePlaceType();
    this.populateState();
    if (!this.isAdd) {
      this.get();
    }
  },
  computed: {
    mayIsee() {
      return this.mayI('place-add', 'place-viewer');
    },
    typeOf() {
      return this.isAdd ? "Adicionar" : "Alterar";
    },
    isAdd() {
      return this.id == '' || this.id == null || this.id == undefined;
    },
    googlemapsURI() {
      //return "https://www.google.com/maps/embed?q=acquplay"
      return `http://maps.google.com/?q=${this.form.ds_googlemaps}&output=embed`;
    }
  },
  methods: {
    gmapsClose() {
      this.$refs.gmapsModal.hide();
    },
    selState() {
      Vue.nextTick().then(response => {
        this.populateCity();
      });
    },
    openMaps() {
      this.popups.gmaps.name = this.form.ds_local_evento;
      this.$refs.gmapsModal.show();
      //window.open("http://maps.google.com/?q="+this.form.ds_googlemaps);
    },
    textChanged($event) {
      if ($event.editor.getLength() > 1000) {
        $event.editor.deleteText(1000, $event.editor.getLength());
      }
    },
    save() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();
      const objectToSave = {
        id_user: this.getLoggedId(),
        id_local_evento: this.isAdd ? '' : this.id,
        ds_local_evento: this.form.ds_local_evento,
        ds_googlemaps: this.form.ds_googlemaps,
        in_ativo: this.form.in_ativo,
        id_municipio: this.form.id_municipio,
        id_tipo_local: this.form.id_tipo_local,
        imagechanged: this.form.saveimage ? '1' : '0',
        imagebase64: this.form.image,
        imagechanged2: this.form.saveimage2 ? '1' : '0',
        imagebase642: this.form.image2,
        url: this.form.url,
        ticketbox_info: this.form.ticketbox_info,
        occupation_info: this.form.occupation_info,
        meta_description: this.form.meta_description,
        meta_keywords: this.form.meta_keywords
      }
      placeService.save(objectToSave).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");

          if (this.validateJSON(response))
          {
             if (response.success) {
               this.toastSuccess("Salvo com sucesso");
               this.$router.push(`/place/list`);
             }
             else {
               this.toastError(response.msg);
             }
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");
          this.toastError("Falha na execução.");
        }
      );      
    },
    populatePlaceType() {
      this.showWaitAboveAll();
      placetypeService.select().then(
        response => {
          this.hideWaitAboveAll();

          if (this.validateJSON(response))
          {
            this.selects.placetype = response;
          }
        },
        error => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );     
    },
    populateState() {
      this.showWaitAboveAll();
      stateService.select().then(
        response => {
          this.hideWaitAboveAll();

          if (this.validateJSON(response))
          {
            this.selects.state = response;
          }
        },
        error => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );     
    },
    populateCity() {
      this.showWaitAboveAll();
      cityService.select(this.form.id_estado).then(
        response => {
          this.hideWaitAboveAll();

          if (this.validateJSON(response))
          {
            this.selects.city = response;
          }
        },
        error => {
          this.hideWaitAboveAll();
          this.toastError("Falha na execução.");
        }
      );     
    },
    get() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      placeService.get(this.id).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          //debugger;

          if (this.validateJSON(response))
          {
            this.form.loaded = this.validateJSONisNotEmpty(response);
              if (this.form.loaded) {
                this.form.ds_local_evento = response.ds_local_evento;
                this.form.ds_googlemaps = response.ds_googlemaps == null ? "" : response.ds_googlemaps;
                this.form.in_ativo = response.in_ativo;
                this.form.id_municipio = response.id_municipio;
                this.form.id_tipo_local = response.id_tipo_local;
                this.form.id_estado = response.id_estado;
                this.form.imageURIBanner = response.imageURIBanner;
                this.form.imageURIOriginal = response.imageURIOriginal;
                this.form.imageURIBanner2 = response.imageURIBanner2;
                this.form.imageURIOriginal2 = response.imageURIOriginal2;
                this.form.url = response.url;
                this.form.ticketbox_info = response.ticketbox_info;
                this.form.occupation_info = response.occupation_info;
                this.form.meta_description = response.meta_description;
                this.form.meta_keywords = response.meta_keywords;
                this.populateCity();
                this.populateImage();
                this.populateImage2();
              }
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      );      
    },
    uploadImageSuccess(formData, index, fileList) {
      //console.log(fileList);
      this.form.saveimage = true;
      this.form.image = fileList[index].path;
      console.log("uploadImageSuccess");
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      this.form.saveimage = false;
      this.form.image = null;
      done();
      console.log("beforeRemove");
    },
    editImage(formData, index, fileList) {
      setTimeout(() => {

        this.form.saveimage = true;
        this.form.image = fileList[index].path;
  
        console.log("editImage");
      }, 100)
    },
    dataChange(data) {
      console.log("dataChange");
      console.log(data);
    },
    populateImage() {
      Vue.nextTick().then(response => {
        this.$wait.start("inprocess");
        this.showWaitAboveAll();
        placeService.base64(this.id, "original").then(
          response => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");

            if (this.validateJSON(response)) {
              this.form.imgbase64 = response.code;
              this.imageobj();
            }
          },
          error => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    imageobj() {
      Vue.nextTick().then(response => {
        let obj = {
          default: 1,
          highlight: 1,
          name: "default.jpg",
          path: this.form.imgbase64
        };
        this.form.images.push(obj);
        this.idupload++;
      });
    },
    uploadImageSuccess2(formData, index, fileList) {
      //console.log(fileList);
      this.form.saveimage2 = true;
      this.form.image2 = fileList[index].path;
      console.log("uploadImageSuccess2");
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove2(index, done, fileList) {
      this.form.saveimage2 = false;
      this.form.image2 = null;
      done();
      console.log("beforeRemove2");
    },
    editImage2(formData, index, fileList) {
      setTimeout(() => {

        this.form.saveimage2 = true;
        this.form.image2 = fileList[index].path;
  
        console.log("editImage2");
      }, 100)
    },
    dataChange2(data) {
      console.log("dataChange2");
      console.log(data);
    },
    populateImage2() {
      Vue.nextTick().then(response => {
        this.$wait.start("inprocess");
        this.showWaitAboveAll();
        placeService.base64(this.id, "original_logo").then(
          response => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");

            if (this.validateJSON(response)) {
              this.form.imgbase642 = response.code;
              this.imageobj2();
            }
          },
          error => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    imageobj2() {
      Vue.nextTick().then(response => {
        let obj = {
          default: 1,
          highlight: 1,
          name: "original_logo.jpg",
          path: this.form.imgbase642
        };
        this.form.images2.push(obj);
        this.idupload2++;
      });
    },
  },
  data () {
    return {
        processing: false,
        loading: false,
        selects: {
          city: [],
          state: [],
          placetype: [],
        },
        popups: {
          gmaps: {
            loaded: false,
            name: '',
          }
        },
        form: {
          loaded: false,
          ds_local_evento: '',
          ds_googlemaps: '',
          in_ativo: 1,
          id_municipio: '',
          id_tipo_local: '',
          id_estado: '',
          imgbase64: "",
          imgbase642: "",
          images: [],
          images2: [],
          image: "",
          image2: "",
          imageURIBanner: "",
          imageOriginalURI: "",
          imageURIBanner2: "",
          imageOriginalURI2: "",
          saveimage: false,
          saveimage2: false,
          url: '',
          ticketbox_info: '',
          occupation_info: '',
          meta_description: '',
          meta_keywords: '',
        },
        idupload: 1,
        idupload2: 2,
        components: {
          quillOptions: {
            modules: {
              toolbar: [
                [
                  {
                    header: [1, 2, false]
                  }
                ],
                ,
                ["bold", "italic", "underline", "image"],
                [
                  {
                    color: []
                  },
                  {
                    background: []
                  }
                ],
                [
                  {
                    align: []
                  }
                ],
                ["clean"]
              ]
            },
            scrollingContainer: "#scrolling-container",
            placeholder: "Digite aqui",
            theme: "snow"
          },
          picOptions: {
            dragText: "Arrastar imagem",
            browseText: "Selecione",
            primaryText: "Padrão",
            markIsPrimaryText: "Definir como padrão",
            popupText: "Esta imagem será exibida como padrão",
            dropText: "Solte aqui"
          },
        },
    }
  }
}
</script>

<style>

</style>
